<template>
  <div class="bg-white">
    <SideBarFilter
      ref="filter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="mb-3">
          <InputText
            textFloat="Search"
            placeholder="Search Name, Member ID, Telephone, Email"
            type="text"
            v-model="filter.Search"
            name="search"
          />
        </div>
        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="Transaction Date From"
            name="Transaction-date-from"
            ref="Transaction-date-from"
            v-model="filter.TransactionDateFrom"
          />
        </div>
        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="Transaction Date To"
            name="Transaction-date-to"
            ref="Transaction-date-to"
            v-model="filter.TransactionDateTo"
          />
        </div>
        <!-- <div class="mb-3">
          <InputDatePickerFilter
            textFloat="First Purchase Date From"
            name="first-purchase-date-from"
            ref="first-purchase-date-from"
            v-model="filter.FirstPurchaseDateFrom"
          />
        </div>
        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="First Purchase Date To"
            name="first-purchase-date-to"
            ref="first-purchase-date-to"
            v-model="filter.FirstPurchaseDateTo"
          />
        </div> -->
        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="Used Date From"
            name="used-date-from"
            ref="used-date-from"
            v-model="filter.UsedDateFrom"
          />
        </div>
        <div class="mb-3">
          <InputDatePickerFilter
            textFloat="Used Date To"
            name="used-date-to"
            ref="used-date-to"
            v-model="filter.UsedDateTo"
          />
        </div>
        <div class="mb-3">
          <label class="main-label"> Used Branch</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filter.BranchFilter"
            :options="options.branches"
            placeholder="Select Branch"
            track-by="id"
            label="branchName"
          ></multiselect>
        </div>
      </template>
    </SideBarFilter>
    <StatPanel :column="statColumn" :data="stat" :isLoading="statLoading" />

    <b-container>
      <b-row>
        <b-col
          cols="12"
          lg="6"
          v-for="chart in chartList"
          :key="chart.name"
          class="resize"
        >
          <b-card
            class="shadow-sm border-none text-black rounded-lg"
            v-if="chart.isChart"
          >
            <div>
              <div class="d-flex justify-content-between mb-3">
                <div class="chart-title-name">{{ chart.name }}</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div
                    :class="`f-14 underline cursor-pointer`"
                    v-for="chartFilter in chart.filter"
                    :key="chartFilter + '-' + chart.name"
                    @click="handleChartFilter(chartFilter, chart)"
                  >
                    <span
                      :class="`${
                        chart.filterValue == chartFilter ||
                        chartFilter == 'Export'
                          ? 'text-highlight'
                          : 'text-gray'
                      }`"
                      >{{ chartFilter }}</span
                    >
                  </div>
                </div>
              </div>

              <template v-if="chart.isFetch">
                <ChartLoading :id="'loading-' + chart.name" type="line" />
              </template>
              <template v-else>
                <BasketChart
                  :id="chart.name"
                  :chartData="chart.data"
                  :text="chart.text"
                  class="mt-auto"
                  :hideLegend="chart.hideLegend"
                  :label="chart.label"
                  :type="chart.type"
                  :filterValue="chart.filterValue"
                  :xText="chart.xText"
                  :isStacked="chart.isStacked"
                />
              </template>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="mb-2 d-flex justify-content-between">
              <div class="chart-title-name">Burn by Salesperson</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[2].filter"
                  :key="chartFilter + '-' + chartList[2].name"
                  @click="handleChartFilter(chartFilter, chartList[2])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.salesperson"
              :items="salesperson.items"
              :busy="salesperson.isBusy"
              show-empty
              foot-clone
              no-footer-sorting
              empty-text="No matching records found"
              @sort-changed="(ctx) => sortingChanged(ctx, chartList[2])"
              :sort-by.sync="salesperson.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="salesperson.filter.sortDesc"
            >
              <template v-slot:cell(treatment_done)="data">
                <span>
                  {{ data.item.treatment_done | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(burn_income)="data">
                <span>
                  {{ data.item.burn_income | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(burn_value)="data">
                <span>
                  {{ data.item.burn_value | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:foot()="data">
                <span v-if="stat[data.field.key]" class="text-center">
                  <span v-if="data.field.key == 'treatment_done'">
                    {{ stat[data.field.key] | numeral("0,0") }}</span
                  >
                  <span v-else>
                    {{ stat[data.field.key] | numeral("0,0.00") }}</span
                  >
                </span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div>
                </span>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="
                (val) => handleChangeTake(val, 'salesperson', chartList[2])
              "
              @pagination="
                (val) => pagination(val, 'salesperson', chartList[2])
              "
              :filter="salesperson.filter"
              :rows="salesperson.rows"
              :limit="2"
            />
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="mb-2 d-flex justify-content-between">
              <div class="chart-title-name">Branch</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[3].filter"
                  :key="chartFilter + '-' + chartList[3].name"
                  @click="handleChartFilter(chartFilter, chartList[3])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <div class="shadow-sm mb-3">
              <b-table
                fixed
                striped
                hover
                :fields="fields.branch"
                :items="branch.items"
                :busy="branch.isBusy"
                show-empty
                empty-text="No matching records found"
                foot-clone
                no-footer-sorting
                @sort-changed="(ctx) => sortingChanged(ctx, chartList[3])"
                :sort-by.sync="branch.filter.sortBy"
                no-local-sorting
                :sort-desc.sync="branch.filter.sortDesc"
              >
                <template v-slot:cell(treatment_done)="data">
                  <span>
                    {{ data.item.treatment_done | numeral("0,0") }}
                  </span>
                </template>
                <template v-slot:cell(burn_income)="data">
                  <span>
                    {{ data.item.burn_income | numeral("0,0.00") }}
                  </span>
                </template>
                <template v-slot:cell(burn_value)="data">
                  <span>
                    {{ data.item.burn_value | numeral("0,0.00") }}
                  </span>
                </template>
                <template v-slot:foot()="data">
                  <span v-if="stat[data.field.key]" class="text-center">
                    <span v-if="data.field.key == 'treatment_done'">
                      {{ stat[data.field.key] | numeral("0,0") }}</span
                    >
                    <span v-else>
                      {{ stat[data.field.key] | numeral("0,0.00") }}</span
                    >
                  </span>
                  <span v-else>
                    <div class="text-left px-2" v-if="data.field.text">
                      {{ data.field.text }}
                    </div>
                  </span>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="
                  (val) => handleChangeTake(val, 'branch', chartList[3])
                "
                @pagination="(val) => pagination(val, 'branch', chartList[3])"
                :filter="branch.filter"
                :rows="branch.rows"
                class="p-2"
              />
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="mb-2 d-flex justify-content-between">
              <div class="chart-title-name">Burn by Year</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[4].filter"
                  :key="chartFilter + '-' + chartList[4].name"
                  @click="handleChartFilter(chartFilter, chartList[4])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <div class="shadow-sm mb-3">
              <b-table
                fixed
                striped
                hover
                :fields="fields.burnYear"
                :items="burnYear.items"
                :busy="burnYear.isBusy"
                show-empty
                empty-text="No matching records found"
                foot-clone
                no-footer-sorting
                @sort-changed="(ctx) => sortingChanged(ctx, chartList[4])"
                :sort-by.sync="burnYear.filter.sortBy"
                no-local-sorting
                :sort-desc.sync="burnYear.filter.sortDesc"
              >
                <template v-slot:cell(treatment_done)="data">
                  <span>
                    {{ data.item.treatment_done | numeral("0,0") }}
                  </span>
                </template>
                <template v-slot:cell(burn_income)="data">
                  <span>
                    {{ data.item.burn_income | numeral("0,0.00") }}
                  </span>
                </template>
                <template v-slot:cell(burn_value)="data">
                  <span>
                    {{ data.item.burn_value | numeral("0,0.00") }}
                  </span>
                </template>
                <template v-slot:foot()="data">
                  <span v-if="stat[data.field.key]" class="text-center">
                    <span v-if="data.field.key == 'treatment_done'">
                      {{ stat[data.field.key] | numeral("0,0") }}</span
                    >
                    <span v-else>
                      {{ stat[data.field.key] | numeral("0,0.00") }}</span
                    >
                  </span>
                  <span v-else>
                    <div class="text-left px-2" v-if="data.field.text">
                      {{ data.field.text }}
                    </div>
                  </span>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="
                  (val) => handleChangeTake(val, 'burnYear', chartList[4])
                "
                @pagination="(val) => pagination(val, 'burnYear', chartList[4])"
                :filter="burnYear.filter"
                :rows="burnYear.rows"
                class="p-2"
              />
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="mb-2 d-flex justify-content-between">
              <div class="chart-title-name">Member</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[5].filter"
                  :key="chartFilter + '-' + chartList[5].name"
                  @click="handleChartFilter(chartFilter, chartList[5])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <div class="shadow-sm mb-3">
              <b-table
                fixed
                striped
                hover
                :fields="fields.member"
                :items="member.items"
                :busy="member.isBusy"
                show-empty
                empty-text="No matching records found"
                foot-clone
                no-footer-sorting
                @sort-changed="(ctx) => sortingChanged(ctx, chartList[5])"
                :sort-by.sync="member.filter.sortBy"
                no-local-sorting
                :sort-desc.sync="member.filter.sortDesc"
              >
                <template v-slot:cell(treatment_done)="data">
                  <span>
                    {{ data.item.treatment_done | numeral("0,0") }}
                  </span>
                </template>
                <template v-slot:cell(burn_income)="data">
                  <span>
                    {{ data.item.burn_income | numeral("0,0.00") }}
                  </span>
                </template>
                <template v-slot:cell(burn_value)="data">
                  <span>
                    {{ data.item.burn_value | numeral("0,0.00") }}
                  </span>
                </template>
                <template v-slot:foot()="data">
                  <span v-if="stat[data.field.key]" class="text-center">
                    <span v-if="data.field.key == 'treatment_done'">
                      {{ stat[data.field.key] | numeral("0,0") }}</span
                    >
                    <span v-else>
                      {{ stat[data.field.key] | numeral("0,0.00") }}</span
                    >
                  </span>
                  <span v-else>
                    <div class="text-left px-2" v-if="data.field.text">
                      {{ data.field.text }}
                    </div>
                  </span>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="
                  (val) => handleChangeTake(val, 'member', chartList[5])
                "
                @pagination="(val) => pagination(val, 'member', chartList[5])"
                :filter="member.filter"
                :rows="member.rows"
                class="p-2"
              />
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="mb-2 d-flex justify-content-between">
              <div class="chart-title-name">Course by Year</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[6].filter"
                  :key="chartFilter + '-' + chartList[6].name"
                  @click="handleChartFilter(chartFilter, chartList[6])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <div class="shadow-sm mb-3">
              <b-table
                fixed
                striped
                hover
                :fields="fields.courseYear"
                :items="courseYear.items"
                :busy="courseYear.isBusy"
                show-empty
                empty-text="No matching records found"
                foot-clone
                no-footer-sorting
                @sort-changed="(ctx) => sortingChanged(ctx, chartList[6])"
                :sort-by.sync="courseYear.filter.sortBy"
                no-local-sorting
                :sort-desc.sync="courseYear.filter.sortDesc"
              >
                <template v-slot:cell(treatment_done)="data">
                  <span>
                    {{ data.item.treatment_done | numeral("0,0") }}
                  </span>
                </template>
                <template v-slot:cell(burn_income)="data">
                  <span>
                    {{ data.item.burn_income | numeral("0,0.00") }}
                  </span>
                </template>
                <template v-slot:cell(burn_value)="data">
                  <span>
                    {{ data.item.burn_value | numeral("0,0.00") }}
                  </span>
                </template>
                <template v-slot:foot()="data">
                  <span v-if="stat[data.field.key]" class="text-center">
                    <span v-if="data.field.key == 'treatment_done'">
                      {{ stat[data.field.key] | numeral("0,0") }}</span
                    >
                    <span v-else>
                      {{ stat[data.field.key] | numeral("0,0.00") }}</span
                    >
                  </span>
                  <span v-else>
                    <div class="text-left px-2" v-if="data.field.text">
                      {{ data.field.text }}
                    </div>
                  </span>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="
                  (val) => handleChangeTake(val, 'courseYear', chartList[6])
                "
                @pagination="
                  (val) => pagination(val, 'courseYear', chartList[6])
                "
                :filter="courseYear.filter"
                :rows="courseYear.rows"
                class="p-2"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import ChartLoading from "@/components/loading/ChartLoading";
import BasketChart from "./../BasketChart";
import Multiselect from "vue-multiselect";

function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
export default {
  components: {
    Multiselect,

    ChartLoading,
    BasketChart,
  },
  data() {
    return {
      isLoading: true,
      statColumn: [
        {
          name: "Total Burn Value",
          key: "burn_value",
          isPercent: false,
          isDecimal: true,
        },

        {
          name: "Burn Income",
          key: "burn_income",
          isPercent: false,
          isDecimal: true,
        },
        {
          name: "Burn QTY",
          key: "treatment_done",
          isPercent: false,
          isDecimal: false,
        },
      ],
      statLoading: true,
      filter: {
        Search: "",
        BranchFilter: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        UsedDateFrom: "",
        UsedDateTo: "",
      },
      // FirstPurchaseDateFrom: "",
      // FirstPurchaseDateTo: "",
      filterFreeze: {
        Search: "",
        BranchFilter: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        UsedDateFrom: "",
        UsedDateTo: "",
      },
      // FirstPurchaseDateFrom: "",
      // FirstPurchaseDateTo: "",
      options: {
        branches: [],
      },
      chartList: [
        {
          name: "Top 5 Burn Income by Branch",
          filterValue: "Month",
          text: "Baht",
          filter: ["Export"],
          label: [],
          data: [],
          export: "top5branch",
          get: () => this.getTop5branch(),
          type: "bar",
          isFetch: false,
          isChart: true,
          hideLegend: true,
        },
        {
          name: "Top 5 Burn Income by Product",
          filterValue: "Month",
          text: "Baht",
          filter: ["Export"],
          label: [],
          data: [],
          export: "top5product",
          get: () => this.getTop5product(),
          type: "bar",
          isFetch: false,
          isChart: true,
          hideLegend: true,
        },
        {
          name: "Burn by Salesperson",
          get: () => this.getSalesPerson(),
          key: "salesperson",
          export: "sale_person",
          filter: ["Export"],
        },
        {
          name: "Burn by Branch",
          get: () => this.getBranch(),
          key: "branch",
          export: "branch",
          filter: ["Export"],
        },
        {
          name: "Burn by Year",
          get: () => this.getBurnYear(),
          key: "burnYear",
          export: "year",
          filter: ["Export"],
        },
        {
          name: "Member",
          get: () => this.getCustomer(),
          key: "member",
          export: "member",
          filter: ["Export"],
        },
        {
          name: "Course",
          get: () => this.getCourseYear(),
          key: "courseYear",
          export: "course_year",
          filter: ["Export"],
        },
      ],
      fields: {
        salesperson: [
          {
            key: "sales_no",
            label: "Salesperson",
            class: "w-20pc",
            sortable: true,
            text: "Total",
          },
          {
            key: "sales_name",
            label: "Sales Name",
            class: "w-20pc",
            sortable: true,
          },
          {
            key: "treatment_done",
            label: "Treatment Done",
            thClass: "w-20pc",
            sortable: true,
          },
          {
            key: "burn_value",
            label: "Total Original Burn Income",
            class: "w-20pc",
            sortable: true,
          },
          {
            key: "burn_income",
            label: "Total Burn Income",
            class: "w-20pc",
            sortable: true,
          },
        ],
        branch: [
          {
            key: "branch_name",
            label: "Branch",
            class: "w-3",
            sortable: true,
            text: "Total",
          },
          {
            key: "treatment_done",
            label: "Treatment Done",
            thClass: "w-3",
            sortable: true,
          },
          {
            key: "burn_value",
            label: "Total Original Burn Income",
            class: "w-3",
            sortable: true,
          },
          {
            key: "burn_income",
            label: "Total Burn Income",
            class: "w-3",
            sortable: true,
          },
        ],
        burnYear: [
          {
            key: "group_year",
            label: "Burn Year",
            class: "w-3",
            sortable: true,
            text: "Total",
          },
          {
            key: "treatment_done",
            label: "Treatment Done",
            thClass: "w-3",
            sortable: true,
          },
          {
            key: "burn_value",
            label: "Total Original Burn Income",
            class: "w-3",
            sortable: true,
          },
          {
            key: "burn_income",
            label: "Total Burn Income",
            class: "w-3",
            sortable: true,
          },
        ],
        member: [
          {
            key: "member_id",
            label: "Member ID",
            class: "w-2",
            sortable: true,
            text: "Total",
          },
          {
            key: "first_name_th",
            label: "Firstname",
            thClass: "w-2",
            sortable: true,
          },
          {
            key: "last_name_th",
            label: "Lastname",
            thClass: "w-2",
            sortable: true,
          },
          {
            key: "telephone",
            label: "Telephone",
            thClass: "w-2",
            sortable: true,
          },
          {
            key: "email",
            label: "Email",
            thClass: "w-2",
            sortable: true,
          },
          {
            key: "treatment_done",
            label: "Treatment Done",
            thClass: "w-2",
            sortable: true,
          },
          {
            key: "burn_value",
            label: "Total Original Burn Income",
            class: "w-2",
            sortable: true,
          },
          {
            key: "burn_income",
            label: "Total Burn Income",
            class: "w-2",
            sortable: true,
          },
        ],
        courseYear: [
          {
            key: "group_year",
            label: "Course by Year",
            class: "w-3",
            sortable: true,
            text: "Total",
          },
          {
            key: "treatment_done",
            label: "Treatment Done",
            thClass: "w-3",
            sortable: true,
          },
          {
            key: "burn_value",
            label: "Total Original Burn Income",
            class: "w-3",
            sortable: true,
          },
          {
            key: "burn_income",
            label: "Total Burn Income",
            class: "w-3",
            sortable: true,
          },
        ],
      },
      salesperson: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          search: "",
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      branch: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      burnYear: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      member: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      courseYear: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      stat: {
        burn_income: 0,
        burn_value: 0,
        treatment_done: 0,
      },
      isFilterProduct: false,
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
        TransactionDateFrom: "",
        TransactionDateTo: "",
        UsedDateFrom: "",
        UsedDateTo: "",
      },
      // FirstPurchaseDateFrom: "",
      // FirstPurchaseDateTo: "",
    };
  },
  async mounted() {
    this.initDateFilter();
    await this.getFilter();
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      return await Promise.all([
        this.getStat(),
        this.getTop5branch(),
        this.getTop5product(),
        this.getSalesPerson(),
        this.getBranch(),
        this.getBurnYear(),
        this.getCustomer(),
        this.getCourseYear(),
      ]);
    },
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DD");
      this.initFilterDate.TransactionDateFrom = moment(toDay)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      this.initFilterDate.TransactionDateTo =
        moment(toDay).format("YYYY-MM-DD");
      // this.initFilterDate.MinDate = moment(toDay).subtract(1, "years").format();
      this.initFilterDate.MaxDate = toDay;
      this.filter.TransactionDateFrom = this.initFilterDate.TransactionDateFrom;
      this.filter.TransactionDateTo = this.initFilterDate.TransactionDateTo;
      this.filter.UsedDateFrom = this.filter.TransactionDateFrom;
      this.filter.UsedDateTo = this.filter.TransactionDateTo;
      this.filterFreeze = { ...this.filter };
    },
    async getTop5branch() {
      this.chartList[0].isFetch = true;
      const res = await this.$report.post(
        `/dashboard/service/burn_summary/top5branch`,
        this.filterFreeze
      );

      let label = [];
      var dataSet = [];
      let color = getRandomColor();
      if (res.data.detail) {
        label = res.data.detail.map((el) => el.branch_name);
        dataSet = [
          {
            label: "Branch",
            type: "bar",
            data: res.data.detail.map((el) => el.burn_income),
            borderColor: color,
            backgroundColor: color,
            fill: false,
          },
        ];
      }

      this.chartList[0].label = label;
      this.chartList[0].data = dataSet;
      this.chartList[0].isFetch = false;
    },
    async getTop5product() {
      this.chartList[1].isFetch = true;
      const res = await this.$report.post(
        `/dashboard/service/burn_summary/top5product`,
        this.filterFreeze
      );
      let label = [];
      var dataSet = [];
      let color = getRandomColor();

      label = res.data.detail.map((el) => el.product_name);
      dataSet = [
        {
          label: "Product",
          type: "bar",
          data: res.data.detail.map((el) => el.burn_income),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];
      this.chartList[1].label = label;
      this.chartList[1].data = dataSet;
      this.chartList[1].isFetch = false;
    },
    async getSalesPerson() {
      this.salesperson.isBusy = true;
      const res = await this.$report.post(
        `/dashboard/service/burn_summary/sale_person`,
        {
          ...this.filterFreeze,
          Search: this.filterFreeze.Search,
          PageNumber: this.salesperson.filter.page,
          RecordPerPage: this.salesperson.filter.take,
          SortColumnId: this.salesperson.filter.SortColumnId,
          SortType: this.salesperson.filter.SortType, // true = ASC, false = DESC
        }
      );

      this.salesperson.items = res.data.detail.data;
      this.salesperson.rows = res.data.detail.total;
      this.salesperson.isBusy = false;
    },

    async getBranch() {
      this.branch.isBusy = true;
      const res = await this.$report.post(
        `/dashboard/service/burn_summary/branch`,
        {
          ...this.filterFreeze,
          Search: this.filterFreeze.Search,
          PageNumber: this.branch.filter.page,
          RecordPerPage: this.branch.filter.take,
          SortColumnId: this.branch.filter.SortColumnId,
          SortType: this.branch.filter.SortType, // true = ASC, false = DESC
        }
      );

      this.branch.items = res.data.detail.data;
      this.branch.rows = res.data.detail.total;
      this.branch.isBusy = false;
    },
    async getStat() {
      this.statLoading = true;
      const res = await this.$report.post(
        `/dashboard/service/burn_summary/analysis`,
        this.filterFreeze
      );

      this.stat = res.data.detail;
      this.statLoading = false;
    },
    async getBurnYear() {
      this.burnYear.isBusy = true;
      const res = await this.$report.post(
        `/dashboard/service/burn_summary/burn_year`,
        {
          ...this.filterFreeze,
          Search: this.filterFreeze.Search,
          PageNumber: this.burnYear.filter.page,
          RecordPerPage: this.burnYear.filter.take,
          SortColumnId: this.burnYear.filter.SortColumnId,
          SortType: this.burnYear.filter.SortType, // true = ASC, false = DESC
        }
      );

      this.burnYear.items = res.data.detail.data;
      this.burnYear.rows = res.data.detail.total;
      this.burnYear.isBusy = false;
    },
    async getCustomer() {
      this.member.isBusy = true;
      const res = await this.$report.post(
        `/dashboard/service/burn_summary/customer`,
        {
          ...this.filterFreeze,
          Search: this.filterFreeze.Search,
          PageNumber: this.member.filter.page,
          RecordPerPage: this.member.filter.take,
          SortColumnId: this.member.filter.SortColumnId,
          SortType: this.member.filter.SortType, // true = ASC, false = DESC
        }
      );

      this.member.items = res.data.detail.data;
      this.member.rows = res.data.detail.total;
      this.member.isBusy = false;
    },
    async getCourseYear() {
      this.courseYear.isBusy = true;
      const res = await this.$report.post(
        `/dashboard/service/burn_summary/course_year`,
        {
          ...this.filterFreeze,
          Search: this.filterFreeze.Search,
          PageNumber: this.courseYear.filter.page,
          RecordPerPage: this.courseYear.filter.take,
          SortColumnId: this.courseYear.filter.SortColumnId,
          SortType: this.courseYear.filter.SortType, // true = ASC, false = DESC
        }
      );

      this.courseYear.items = res.data.detail.data;
      this.courseYear.rows = res.data.detail.total;
      this.courseYear.isBusy = false;
    },
    pagination(val, key, chart) {
      this[key].filter.page = val;
      chart.get();
    },
    handleChangeTake(val, key, chart) {
      this[key].filter.take = val;
      this[key].filter.page = 1;
      chart.get();
    },
    async getFilter() {
      const res = await this.$report(`/dashboard/Filter/Branch`);

      this.options.branches = res.data.detail;
    },
    async export(path) {
      this.$bus.$emit("showLoading");
      const res = await this.$report.post(
        `/dashboard/service/burn_summary/report/${path}`,
        this.filterFreeze,
        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", path + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async exportStore() {
      this.$bus.$emit("showLoading");
      const res = await this.$report.post(
        `/RevenueReport/ReportStore`,
        this.filterFreeze,
        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "reportStore" + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async handleChartFilter(filter, chart) {
      let value = 0;
      if (filter == "Day") value = 3;
      if (filter == "Month") value = 2;
      if (filter == "Year") value = 1;
      if (filter == "Export") return this.export(chart.export);
      if (filter != "Export") chart.filterValue = filter;
      this.filter.TypeDateFilter = value;
      this.filterFreeze.TypeDateFilter = value;
      await chart.get();
    },
    clearFilter() {
      this.filter = {
        BranchFilter: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        UsedDateFrom: "",
        UsedDateTo: "",
      };
      // FirstPurchaseDateFrom: "",
      // FirstPurchaseDateTo: "",
      this.filterFreeze = {
        BranchFilter: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        UsedDateFrom: "",
        UsedDateTo: "",
      };
      // FirstPurchaseDateFrom: "",
      // FirstPurchaseDateTo: "",
      this.$store.dispatch("clearFilter");
      this.$refs.filter.hide();
      this.filterActions();
    },
    filterActions() {
      if (this.filter.TransactionDateFrom)
        this.filter.TransactionDateFrom = this.$moment(
          this.filter.TransactionDateFrom
        ).format("YYYY-MM-DD");
      if (this.filter.TransactionDateTo)
        this.filter.TransactionDateTo = this.$moment(
          this.filter.TransactionDateTo
        ).format("YYYY-MM-DD");
      // if (this.filter.FirstPurchaseDateFrom)
      //   this.filter.FirstPurchaseDateFrom = this.$moment(
      //     this.filter.FirstPurchaseDateFrom
      //   ).format("YYYY-MM-DD");
      // if (this.filter.FirstPurchaseDateTo)
      //   this.filter.FirstPurchaseDateTo = this.$moment(
      //     this.filter.FirstPurchaseDateTo
      //   ).format("YYYY-MM-DD");
      if (this.filter.UsedDateFrom)
        this.filter.UsedDateFrom = this.$moment(
          this.filter.UsedDateFrom
        ).format("YYYY-MM-DD");
      if (this.filter.UsedDateTo)
        this.filter.UsedDateTo = this.$moment(this.filter.UsedDateTo).format(
          "YYYY-MM-DD"
        );

      this.filterFreeze = { ...this.filter };
      for (const [keys, value] of Object.entries(this.filter)) {
        if (typeof value == "object") {
          this.filterFreeze[keys] = value.map((el) => el.id);
        }
      }

      this.getData();
    },
    async sortingChanged(ctx, chart) {
      let key = chart.key;
      let index = this.fields[key].findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this[key].filter.SortType &&
        !ctx.sortDesc &&
        this[key].filter.SortColumnId == index
      ) {
        this[key].filter.SortColumnId = 0;
        this[key].filter.SortColumnId = 0;
        this[key].filter.sortBy = "";
        this[key].filter.sortDesc = "";
      } else {
        this[key].filter.SortColumnId = index;
        this[key].filter.SortType = ctx.sortDesc;
      }
      await chart.get();
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
.text-gray {
  color: whitesmoke;
}
.chart-title-name {
  font-size: 18px;
  font-weight: bold;
  color: black;
  /* margin-bottom: 0.75rem; */
}

.input-select .select-custom label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
